@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  .main-component {
    .btn {
      margin-left: .5rem;
      margin-right: .5rem;
    }

    .icon-btn {

      font-size: 3rem;
      color: #3A405A;
      height: fit-content;
      transition: color .2s ease-in-out;
      &:hover {
        color: #7483A3;
      }
    }
  }
}